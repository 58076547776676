import React from 'react';
import { Card, CardActions, CardMedia, Button, Typography, ButtonBase } from '@material-ui/core/';
// import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
// import ThumbUpAltOutlined from '@material-ui/icons/ThumbUpAltOutlined';
// import InfoIcon from '@material-ui/icons/Info';
import { useDispatch } from 'react-redux';
// import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { deletePost } from '../../../actions/posts';
import useStyles from './styles';

const Post = ({ post, setCurrentId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('profile'));
  const history = useHistory();

  const openPost = (e) => {
    // dispatch(getPost(post._id, history));

    history.push(`/posts/${post._id}`);
  };

  return (
    <Card className={classes.card} raised elevation={6}>
      <ButtonBase
        component="span"
        name="test"
        className={classes.cardAction}
        onClick={openPost}
      >
        <CardMedia className={classes.media} image={post.selectedFile || 'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png'} title={post.title} />
        <div className={classes.overlay}>
          <Typography variant="h6">{post.title}</Typography>
          {/* <Typography variant="body2">{moment(post.createdAt).fromNow()}</Typography> */}
        </div>
        <div className={classes.details}>
          <Typography variant="body2" color="textSecondary" component="h2">{post.tags.map((tag) => `${tag}  `)}</Typography>
        </div>
        {/* <Typography className={classes.title} gutterBottom variant="h5" component="h2">{post.title}</Typography> */}
        {/* <CardContent> */}
          {/* <Typography variant="body2" color="textSecondary" component="p">{post.message.split(' ').splice(0, 25).join(' ')}...</Typography> */}
        {/* </CardContent> */}
      </ButtonBase>
      <CardActions className={classes.cardActions}>
        {(user?.result?.googleId === post?.creator || user?.result?._id === post?.creator) && (
          <Button size="small" color="secondary" onClick={() => dispatch(deletePost(post._id))}>
            <DeleteIcon fontSize="small" /> &nbsp; Delete
          </Button>
        )}
        {(user?.result?.googleId === post?.creator || user?.result?._id === post?.creator) && (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setCurrentId(post._id);
            }}
            style={{ color: 'black' }}
            size="small"
          >
            <MoreHorizIcon fontSize="default" />Edit
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default Post;

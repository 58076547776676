import React, { useEffect } from 'react';
import { Paper, Typography, CircularProgress, Divider } from '@material-ui/core/';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import { getPost, getPostsBySearch } from '../../actions/posts';
import useStyles from './styles';

const Post = () => {
  const { post, isLoading } = useSelector((state) => state.posts);
  const dispatch = useDispatch();
  // const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getPost(id));
  // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (post) {
      dispatch(getPostsBySearch({ search: 'none', tags: post?.tags.join(',') }));
    // eslint-disable-next-line
    }
  // eslint-disable-next-line
  }, [post]);

  if (!post) return null;

  // const openPost = (_id) => history.push(`/posts/${_id}`);

  if (isLoading) {
    return (
      <Paper elevation={6} className={classes.loadingPaper}>
        <CircularProgress size="7em" />
      </Paper>
    );
  }

  // const recommendedPosts = posts.filter(({ _id }) => _id !== post._id);

  return (
    <Paper style={{ padding: '20px', borderRadius: '15px' }} elevation={6}>
      <div className={classes.card}>
        <div className={classes.section}>
          <Typography variant="h3" component="h2">{post.title}</Typography>
          <Typography gutterBottom variant="h6" component="h2">Searchable Terms:</Typography>
          <Typography gutterBottom variant="h6" color="textSecondary" component="h2">{post.tags.map((tag) => `${tag} `)}</Typography>
          <Typography gutterBottom variant="h6" component="h2"><strong>Main Details </strong></Typography>
          <Typography gutterBottom variant="body1" component="p">Location: {post.location}</Typography>
          <Typography gutterBottom variant="body1" component="p">Count: {post.count}</Typography>
          <Typography gutterBottom variant="body1" component="p">Cert: {post.cert}</Typography>
          <Typography gutterBottom variant="body1" component="p">Cost: {post.cost}</Typography>
          <Divider style={{ margin: '20px 0' }} />
          <Typography variant="h5"><strong>Additional Details</strong></Typography>
          <Typography gutterBottom variant="body1" component="p">{post.notes}</Typography>
          {/* <Typography variant="body1"><strong>Realtime Chat - coming soon!</strong></Typography> */}
          <Divider style={{ margin: '20px 0' }} />
          <Typography variant="h6"><strong>Added: </strong></Typography>
          <Typography variant="body1">{moment().format('LL', post.createdAt)}</Typography>
          {/* <Typography variant="body1"><strong>Comments - coming soon!</strong></Typography> */}
          {/* <Divider style={{ margin: '20px 0' }} /> */}
        </div>
        <div className={classes.imageSection}>
          <img className={classes.media} src={post.selectedFile || 'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png'} alt={post.title} />
        </div>
      </div>
    </Paper>
  );
};

export default Post;
